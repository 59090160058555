@import '../../sass/style.scss';

.ProductDetail {
    min-height: calc(100vh - 348px);
    .product__img {
        background-color: #EDEDED;
        padding: 12px;
        border-radius: 20px;

        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .product__content {
        padding-top: 50px;

        .content__title {
            h3 {
                font-size: 20px;
                font-weight: 700;
                color: #9E874A;
                padding-bottom: 12px;
            }
        }

        .product__inner-content {
            font-size: 16px;
            font-weight: 400;
            color: #242424;
            text-align: justify;
        }
    }

    .product__info {
        background-color: $chineseWhite;
        border-radius: 20px;
        padding: 40px 30px 30px 30px;

        .product__heading {
            border-bottom: 1px solid #A1A1A1;
            padding-bottom: 8px;
        }

        .product__cart {
            border-bottom: 1px solid #A1A1A1;
            padding: 24px 0px 12px;

            .price__lable {
                font-size: 20px;
                line-height: 22px;
                font-weight: 700;
                color: $primaryHeadingColor;
                background: $white;
                padding: 14px 20px;
                border-radius: 100px;
            }

            .cart__add--btn {
                background-color: #F2F2F2;
                border-radius: 100px;
                padding: 4px;

                .MuiButtonGroup-root {
                    border-radius: 100px;
                    background-color: $white;
                    margin: 0;

                    .MuiButtonBase-root {
                        color: #242424;
                        border: none;
                        padding: 5px 9px;
                        font-size: 18px;
                        font-weight: 700;

                        &.MuiButtonGroup-grouped {
                            min-width: unset;
                        }
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border: none;
                    }
                }

                .btn-addCart {
                    background-color: #E15A0E;
                    padding: 8px 12px;
                    border-radius: 100px;
                    font-size: 14px;
                    font-weight: 700;
                    box-shadow: none;
                }

                .btn-disableCart {
                    background-color: #A1A1A1;
                    padding: 8px 12px;
                    border-radius: 100px;
                    font-size: 14px;
                    font-weight: 700;
                    box-shadow: none;
                }
            }

            .hr-line {
                border-top: 1px dashed #A1A1A1;
                opacity: 1;
            }

            .request__sample {
                padding: 6px;
                background: transparent;
                border-radius: 100px;

                &.active {
                    background: white;
                    border-radius: 100px;

                    .MuiButtonBase-root {
                        background-color: #F0F0F0;
                    }

                    .MuiTypography-root {
                        margin-right: 16px;
                    }
                }

                .MuiButtonBase-root {
                    height: 50px;
                    width: 50px;
                    min-width: unset;
                    background: $white;
                    padding: 10px;
                    border-radius: 50%;
                    box-shadow: none;
                    margin-left: 0;

                    &:hover {
                        background-color: $antiFlashWhite;
                    }
                }
            }

            .make__offer {
                padding: 6px;
                background: transparent;
                border-radius: 100px;

                &.active {
                    background: white;
                    border-radius: 100px;

                    .MuiButtonBase-root {
                        background-color: #F0F0F0;
                    }

                    .MuiTypography-root {
                        margin-right: 16px;
                    }
                }

                .MuiButtonBase-root {
                    height: 50px;
                    width: 50px;
                    min-width: unset;
                    background: $white;
                    padding: 10px;
                    border-radius: 50%;
                    box-shadow: none;
                    margin-left: 0;
                    margin: 0;

                    &:hover {
                        background-color: $antiFlashWhite;
                    }
                }
            }

            .request__sample--details {
                .card {
                    &.card-body {
                        background-color: $LightSilver;
                        border-radius: 10px;

                        .primary-btn {
                            background-color: #496673;
                            color: #F8F8F8;
                        }

                        .secondary-btn {
                            color: #496673;
                            background-color: #F8F8F8;
                        }


                        .form-label {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 22px;
                        }

                        .form-select {
                            border-bottom: 1px solid #616161;;
                            // border-radius: 50px;
                            // padding: 12px 16px;

                            &:focus {
                                box-shadow: none;
                                border-color: transparent;
                                border-bottom: 2px solid #0F6CBD;
                          
                                &.is_invalid {
                                  border-bottom: 2px solid #EC2323;
                                }
                              }

                              &.is_invalid {
                                box-shadow: none;
                                border-bottom: 2px solid #EC2323;   
                              }
                        }

                        .form-control {
                            border-bottom: 1px solid #616161;;
                            // border-radius: 50px;
                            // padding: 12px 16px;

                            &.textarea {
                                border-bottom: 1px solid #616161;;
                                border-radius: 10px;
                            }

                        
                            &:focus {
                                border-bottom: 2px solid #0F6CBD;
                                box-shadow: none;
                          
                                &.is_invalid {
                                  border-bottom: 2px solid #EC2323;
                                }
                              }

                              &.is_invalid {
                                box-shadow: none;
                                border-bottom: 2px solid #EC2323;   
                              }
                        }

                        .error-message {
                            color: #EC2323;
                            font-size: 0.875em;
                            margin-top: 0.25em;
                          }

                        .form-check-label {
                            color: #333333;
                        }
                    }
                }
            }

            .make__offer--details {
                .card {
                    &.card-body {
                        background-color: $LightSilver;
                        border-radius: 10px;


                        .MuiFormControl-root {
                            .MuiInputBase-root {
                                background-color: $white;
                            }
                        }

                        .primary-btn {
                            background-color: #496673;
                            color: #F8F8F8;
                        }

                        .skip-btn {
                            background-color: #f0f0f0;
                            color: #6c757d;
                          }

                        .secondary-btn {
                            color: #496673;
                            background-color: #F8F8F8;
                        }

                    }
                }
            }
        }

        .MuiPaper-root {
            box-shadow: none;
            background-color: transparent;

            .MuiTable-root {
                .MuiTableBody-root {
                    .MuiTableRow-root {
                        .MuiTableCell-root {
                            &:first-child {
                                font-weight: 700;
                                font-size: 14px;
                                border: none;
                                text-wrap: nowrap;
                                text-transform: uppercase;
                                padding: 12px 12px 12px 12px;
                            }

                            &:last-child {
                                font-size: 14px;
                                font-weight: 600;
                                border: none;
                                padding: 12px 12px 12px 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .MuiTypography-root {
        &.MuiTypography-h5 {
            margin-top: 24px;
            padding-top: 24px;
            font-size: 18px;
            font-weight: 700;
            color: #242424;
        }

        &.MuiTypography-body1 {
            font-size: 14px;
            font-weight: 400;
            color: #242424;
        }
    }
}

.ais-Hits {
    .ais-Hits-list {
        padding-left: 0;
    }
}

.customer--view__cart {
    background-color: #F8F8F8;
    padding: 48px 0px;
    .customer--view-cart-inner{
        min-height: calc(100vh - 412px);
    .card {
        &.product__card {
            background-color: #EBEBEB;
            border: 1px dashed #9747FF;
            .my-cart-remobe--btn{
                border: 1px solid #616161;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 5px;
                height: 45px;
                padding: 5px 10px;
                font-family: Segoe UI;
                margin-top: 4px;
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #242424;
            }
            .cart__add--btn {
                background-color: #F2F2F2;
                border-radius: 100px;
                padding: 4px;
                height: max-content;

                .MuiButtonGroup-root {
                    border-radius: 100px;
                    background-color: $white;
                    margin: 0;

                    .MuiButtonBase-root {
                        color: #242424;
                        border: none;
                        padding: 5px 9px;
                        font-size: 18px;
                        font-weight: 700;

                        &.MuiButtonGroup-grouped {
                            min-width: unset;
                        }
                    }

                    .MuiInputBase-input {
                        border: none;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border: none;
                    }
                }

                .btn-addCart {
                    background-color: #E15A0E;
                    padding: 8px 12px;
                    border-radius: 100px;
                    font-size: 14px;
                    font-weight: 700;
                    box-shadow: none;
                }
            }
        }

        &.order__summary--card {
            border-radius: 20px;

            .card-header {
                border-radius: 20px 20px 0 0;
            }

            .card-body {
                .total-price-sec {
                    .list-group-item {
                        background-color: #F2F2F2;
                    }
                }
            }

            .card-footer {
                border-radius: 0 0 20px 20px;
            }
        }
    }
}
}

.grid-view {
    &.barrel__suggestion-grid {
        padding: 8px 0px;

        .ais-Hits {
            .ais-Hits-list {
                display: grid;
                grid-template-columns: repeat(5, minmax(0, 1fr));

                @media screen and (max-width:1400px) {
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }

                @media screen and (max-width:991px) {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }

                @media screen and (max-width:767px) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                @media screen and (max-width:575px) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }

                .ais-Hits-item {
                    padding: 8px;
                }
            }
        }
    }
}

.viewSampleRequest--wrapper {
    .order__summary--card {
        background: #F0F0F0;
        border-radius: 20px;

        .card-header {
            background: #F0F0F0;
            border-radius: 20px 20px 0px 0px;
            border-bottom-color: #E4E4DD;
        }
    }
}
@media (max-width:1399.98px) {
    .customer--view__cart .customer--view-cart-inner {
        min-height: calc(100vh - 362px);
    }
    .success-order-wrapper {
        min-height: calc(100vh - 266px);
    }
}
@media (max-width:1650px) {
    .ProductDetail .product__info .product__heading h2{
        font-size: 22px;
    }
    .ProductDetail .product__info .product__cart .price__lable{
        font-size: 17px;
    }.ProductDetail .product__info .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root{
        font-size: 13px;
    }
    .ProductDetail .product__content .content__title h3{
        font-size: 18px;
    }
    .ProductDetail .product__content .product__inner-content,
    .ProductDetail .product__content .product__inner-content h6{
        font-size: 15px;
    }
    .customer--view__cart .card.product__card .card-header .MuiTypography-root,
    .customer--view__cart .card.product__card .card-content-wrapper .card-body h6.MuiTypography-root,
    .customer--view__cart .card.order__summary--card .card-footer .list-group p.MuiTypography-root{
        font-size: 18px;
    }
}