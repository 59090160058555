.badge-submit {
    background-color: $brightGray;
    color: $charcoal;
}
.badge-invite {
    background-color: $lightSeaGreen;
    color: $white;
}
.badge-approve {
    background-color: $americanGreen;
    color: $white;
}