@import '../../sass/style.scss';

.data-grid {
    &.MuiDataGrid-root {
        border: unset;

        .MuiDataGrid-main {
            background-color: $white;
            font-family: 'Segoe UI Regular';
            border-radius: 4px;
            padding: 0px;

            .MuiDataGrid-virtualScroller {
                .MuiDataGrid-topContainer {
                    border-radius: 8px;

                    &::after {
                        content: none;
                    }

                    .MuiDataGrid-columnHeaders {
                        border-radius: 8px;

                        [role=row] {
                            background: $antiFlashWhite;
                            border-bottom: unset;

                            .MuiDataGrid-columnHeader {
                                .MuiDataGrid-columnHeaderDraggableContainer {
                                    .MuiDataGrid-columnHeaderTitleContainer {
                                        .MuiDataGrid-columnHeaderTitleContainerContent {
                                            .MuiDataGrid-columnHeaderTitle {
                                                color: #6F6F6F;
                                                font-size: 14px;
                                                line-height: 18px;
                                            }
                                        }
                                    }
                                }

                                &:focus {
                                    outline: unset;
                                }

                                &:focus-within {
                                    outline: unset;
                                }

                                .MuiCheckbox-indeterminate {
                                    color: $secondaryTextColor;
                                }

                                .Mui-checked {
                                    color: $secondaryTextColor;
                                }
                            }
                        }
                    }
                }

                .MuiDataGrid-virtualScrollerContent {
                    .MuiDataGrid-virtualScrollerRenderZone {
                        .MuiDataGrid-row {
                            .MuiDataGrid-cell {
                                &.status-badge {
                                    overflow: visible;
                                }

                                &:focus {
                                    outline: unset;
                                }

                                &:focus-within {
                                    outline: unset;
                                }

                                .MuiIconButton-root {
                                    padding: 4px;

                                    svg {
                                        fill: #1C1B1F;
                                        width: 20px;
                                        height: auto;

                                        path {
                                            fill: #1C1B1F;
                                        }
                                    }
                                }
                            }

                            &.Mui-selected {
                                background-color: #97aeb812;

                                .MuiDataGrid-cellCheckbox {
                                    color: unset;

                                    .Mui-checked {
                                        color: $secondaryTextColor;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .MuiDataGrid-scrollbar {

                /* width */
                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                    border-radius: 10px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $pewterBlue;
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: $graniteGray;
                }
            }
        }

        // .MuiDataGrid-footerContainer {
        //     display: none;
        // }
    }
}
// .company-dashboard-data-grid {height: 200px;}
/* Add this CSS to your stylesheet */
.data-grid .MuiDataGrid-row:hover {
    cursor: pointer;
  }
  