.login {
    font-family: 'Segoe UI Regular';
    background-color: #EAECEE;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 32px;

    .login__form {
        background-color: $white;
        max-width: 1000px;
        padding: 20px;
        border-radius: 25px;
        box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.1);

        .login__form--outer {
            padding-inline: 32px;
            // .logo__wrapper {
                    .logo-sub__text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: Inter;
                        font-weight: 400;
                        line-height: 16.94px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #061922;
                        font-size: 14px;
                        padding-inline: 8px;
                        z-index: 10;
                        position: relative;
            
                        &::after {
                            content: '';
                            flex: 1;
                            border-bottom: 1px solid #061922;
                            /* Match the text color */
                            margin: 0 10px;
                        }
            
                        &::before {
                            content: '';
                            flex: 1;
                            border-bottom: 1px solid #061922;
                            /* Match the text color */
                            margin: 0 10px;
                        }
                    }
                // }
            .bg-none {
                background-image: none;
            }
            .back-btn{
                border: unset;
                padding-left: 0px;
                margin-bottom: 20px;
                margin-left: -10px;
                &:focus{
                    border: unset;
                }
            .arrow-wrapper{
                background-color: #F0F0F0;
                height: 40px;
                width: 40px;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: #1C1B1F;
            }
            .back-btn-text{
                font-family: Segoe UI;
                font-size: 18px;
                font-weight: 400;
                line-height: 23.94px;
                color:$primaryTextColor ;

            }
        }

            .login__heading {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
            }

            .login__text {
                color: $silverChalice;
            }

            .form__inner {
                .form-control {
                    border-radius: 4px;
                    border-bottom-color: #616161;
                
                    &::placeholder {
                      color: $silverChalice;
                    }
                
                    &:focus {
                      border-bottom: 2px solid #0F6CBD;
                
                      &.is_invalid {
                        border-bottom: 2px solid #EC2323;
                      }
                    }

                    &.is_invalid {
                        box-shadow: none;
                        border-bottom: 2px solid #EC2323;   
                      }
                      
                    
                  }

                  .error-message {
                    color: #EC2323;
                    font-size: 0.875em;
                    margin-top: 0.25em;
                  }
                

                .form-label {
                    color: $primaryHeadingColor;
                    font-weight: 600;
                }

                .form-check {
                    .form-check-input {
                        &:checked {
                            background-color: $pewterBlue;
                            border-color: $pewterBlue;
                        }
                    }

                    .form-check-label {
                        color: $graniteGray;

                        &:hover {
                            color: $primaryHeadingColor;
                        }
                    }
                }

                .form-link {
                    a {
                        color: $graniteGray;

                        &:hover {
                            color: $primaryHeadingColor;
                        }
                    }
                }

                .form__btn--group {
                    div {
                        .register-btn {
                            min-width: 225px;
                        }
                    }
                }
            }
        }
    }

    .login__form--banner {
        border-radius: 25px;
        max-height:100%;

        img {
            max-width: 100%;
            object-fit: cover;
            border-radius: 25px;
            max-height: 100%;
        }
    }
}

.form-check {
    .form-check-input {
        &:checked {
            background-color: $pewterBlue;
            border-color: $pewterBlue;
        }
    }

    .form-check-label {
        color: $graniteGray;

        &:hover {
            color: $primaryHeadingColor;
        }
    }
}

@media screen and (max-width: 1399px) {
    .login .login__form--banner img {
        max-height: 650px;
    }
}

@media screen and (max-width: 1299px) {
    .login .login__form--banner img {
        max-height: 600px;
    }
}

@media screen and (max-width: 1024px) {
    .login .login__form {
        max-width: 900px;
    }

    .login .login__form--banner img {
        max-height: 575px;
    }
}

@media screen and (max-width: 991px) {
    .login .login__form {
        max-width: 900px;
    }

    .login .login__form .login__form--outer {
        padding: 56px 32px;
        background-image: url('../../assets/images/login/LoginBanner.png');
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.9);
        border-radius: 25px;

    }

    .login .login__form .login__form--outer.bg-none {
        background-image: unset;
    }

    .login .login__form .login__form--outer .form__inner {
        max-width: 450px;
    }

    .login .login__form .login__form--outer .login__text {
        color: $primaryTextColor;
    }

    .login .login__form .login__form--outer .form__inner .secondary-btn {
        background: $pewterBlue;
        color: $white;
    }
}

@media screen and (max-width: 575px) {
    .login {
        // margin: 32px 12px;
        padding: 16px;
    }

    .login .login__form {
        padding: 20px 12px;
    }

    .login .login__form .login__form--outer {
        padding: 48px 20px;
    }
}

// Toggle Switch CSS
.toggle__switch-checkbox {
    --toggle-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 3.75em;
    min-width: 3.75em;
    height: 2em;
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #efefef), color-stop(50%, #1E5EFF)) no-repeat;
    background: -o-linear-gradient(left, #efefef 50%, #1E5EFF 50%) no-repeat;
    background: linear-gradient(to right, #efefef 50%, #1E5EFF 50%) no-repeat;
    background-size: 205%;
    background-position: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-radius: 99em;
    position: relative;
    cursor: pointer;
    font-size: var(--toggle-size);

    &::before {
        content: "";
        width: 1.25em;
        height: 1.25em;
        position: absolute;
        top: 0.438em;
        left: 0.438em;
        background: -webkit-gradient(linear, left top, right top, color-stop(50%, #efefef), color-stop(50%, #2a2a2a)) no-repeat;
        background: -o-linear-gradient(left, #efefef 50%, #2a2a2a 50%) no-repeat;
        background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
        background-size: 205%;
        background-position: 100%;
        border-radius: 50%;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
    }

    &:checked::before {
        left: calc(100% - 1.25em - 0.438em);
        background-position: 0;
    }

    &:checked {
        background-position: 100%;
    }
}

.file__upload--container {
    color: $primaryHeadingColor;

    .file__upload--dropzone {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 24px 16px;
        border: 1px dashed $pewterBlue;
        border-radius: 4px;

        .fp-label {
            background: $pewterBlue;
            color: $white;
            // border-radius: 4px;
            padding: 8px 16px;
            cursor: pointer;
        }
    }

    .file__upload--dropzone-danger {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 24px 16px;
        border: 1px dashed #dc3545;
        border-radius: 4px;

        .fp-label {
            background: $pewterBlue;
            color: $white;
            // border-radius: 4px;
            padding: 8px 16px;
            cursor: pointer;
        }
    }

    .file__upload--info {
        // background: $secondaryBtnBg;
        color: $primaryTextColor;
        padding: 8px 16px 8px 0px;
        border-radius: 100px;
        font-weight: 600;
        border: 1px solid $transparent;

        .file-name {
            color: #737376;
            font-size: 15px;
            font-weight: 400;
        }

        .file-delete {
            cursor: pointer;
            color: #737376;
            font-size: 15px;
            font-weight: 400;
        }

    }

    .MuiLinearProgress-root {
        background: #E4E4DD;
        border-radius: 5px;


        .MuiLinearProgress-bar {
            background-color: #95CF48;
        }
    }
}

.file__upload--container-ttb {
    .labelFile {
        p {
            color: $primaryHeadingColor;
            cursor: pointer;
            text-decoration: underline $primaryHeadingColor;
            text-underline-offset: 4px;
        }

        .css-me1rrz-MuiSvgIcon-root {
            color: $primaryHeadingColor;
        }
    }

    .input-ttbFile {
        max-width: 190px;
        display: none;
    }

    .file__upload--info {
        background: $secondaryBtnBg;
        color: $primaryTextColor;
        padding: 4px 6px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        border: 1px solid $transparent;

        .file-name {
            text-decoration: none;
        }

        .file-delete {
            cursor: pointer;
        }
    }
}

// Search Bar CSS
.searchbar__input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    position: relative;

    .searchbar__input--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        outline: none;
        border-style: none;
        border-radius: 50%;
        pointer-events: painted;
        background-color: transparent;
        transition: 0.2s linear;

        &:focus {
            ~.searchbar__input {
                box-shadow: none;
                width: 180px;
                border-radius: 100px;
                background-color: $white;
                border: 1px solid $transparent;
                transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
            }
        }

    }

    .searchbar__input {
        border-style: none;
        height: 40px;
        width: 40px;
        padding: 10px;
        outline: none;
        border-radius: 50%;
        transition: 0.5s ease-in-out;
        background-color: $white;
        box-shadow: 0px 0px 3px $white;
        padding-right: 30px;
        color: $primaryTextColor;
        text-indent: 6px;

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }

        &::placeholder {
            color: $secondaryTextColor;
        }

        &:focus {
            box-shadow: none;
            width: 180px;
            border-radius: 100px;
            background-color: $white;
            border: 1px solid $transparent;
            transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
        }
    }
}

// Custom Radio Buttons

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #496673;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:not(:checked) + label:before {
    border: 2px solid #49454F;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #496673;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}