.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.error-msg {
  color: red;
}

.logout {
  float: right;
  margin-right: 2%;
}

.ais-RefinementList-list {
  list-style: none;
}

.ais-Pagination-list {
  display: flex;
  list-style: none;
}

.ais-Hits-item {
  list-style: none;
}

.ais-Pagination-item {
  margin-left: 20px;
}

.invalid {
  border-color: indianred;
}

/* Active tab styles */
.active-tab {
  background-color: #EAECEE !important;
  color: #333 !important;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: darkblue;
}

.invited-row .MuiDataGrid-checkboxInput {
  pointer-events: none;
  /* Disable pointer events */
  opacity: 0.5;
  /* Reduce opacity to visually indicate disabled state */
  cursor: not-allowed;
  /* Show not-allowed cursor */
}

.names {
  color: white;
}

.marg {
  margin-right: 6px;
}

.radio-text {
  margin-left: 3px;
}

/* styles.css */
.status-cell {
  display: flex;
  align-items: center;
  margin-top: 13px;

}

.info-icon {
  margin-left: 8px;
  /* Adjust as needed */
  cursor: pointer;
  color: #6c757d;
  /* Default Bootstrap text color for disabled elements */
}

.navbar .dropdown-menu .dropdown-item:active {
  --bs-dropdown-link-active-bg: transparent !important;
}

.odr {
  display: flex;
}

.mrl {
  margin-left: 7px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.sent {
  float: right;
  clear: both;
  background-color: lightblue;
  /* Example styling */
}

.received {
  float: left;
  clear: both;
  background-color: lightgreen;
  /* Example styling */
}


.sent-text {
  /* float: right; */
  font-weight: bold;
  color: blue;
  /* Example styling for sent messages */
}

.received-text {
  /* float: left; */
  font-weight: bold;
  color: green;
  /* Example styling for received messages */
}

.title-grid {
  margin-left: 30px;
  font-weight: 700;
  margin-top: 1%;
  float: left;
  width: 700px;
}

.invalid-message{
  display: block;
    font-size: .875em;
    color: #dc3545;
}

/* styles.css */
.uat-banner {
  height: 50px;
  gap: 10px;
  background: #092633;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uat-text {
  font-family: 'Segoe UI', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF; /* Adjust the text color if needed */
}

.disabled-div {
  opacity: 0.5;
  pointer-events: none; /* This will prevent interaction with the div */
}
