.btn {
    &:active {
        border: none;
        color: unset;
        background-color: unset;
    }
}

.primary-btn {
    background: $pewterBlue;
    color: $white;
    padding: 8px 16px;
    border-radius: 100px;
    font-weight: 600;
    border: 1px solid $transparent;

    &:hover {
        // background: $secondaryBtnBg;
        // color: $primaryTextColor;
        // border: 1px solid $transparent;
        background: $pewterBlue;
        opacity: 0.9;
        color: $white;
    }

    &:active {
        background-color: $secondaryBtnBg;
        color: $primaryTextColor;
        border: 1px solid $transparent;
    }
}

.skip-btn {
    background: #d1ecf1;
    color: #0c5460;
    padding: 8px 16px;
    border-radius: 100px;
    font-weight: 600;
    border: 1px solid $transparent;

    &:hover {
        // background: $secondaryBtnBg;
        // color: $primaryTextColor;
        // border: 1px solid $transparent;
        background: #d1ecf1;
        opacity: 0.9;
        color: #0c5460;
    }

    &:active {
        background-color: $secondaryBtnBg;
        color: $primaryTextColor;
        border: 1px solid $transparent;
    }
}

.secondary-btn {
    background: $secondaryBtnBg;
    color: $primaryTextColor;
    padding: 8px 16px;
    border-radius: 100px;
    font-weight: 600;
    border: 1px solid $transparent;

    &:hover {
        // background: $pewterBlue;
        // color: $white;
        // border: 1px solid $transparent;
        background: $americanSilver;
        color: $primaryTextColor;
        opacity: 0.9;
    }

    &:active {
        background: $secondaryBtnBg;
        color: $primaryTextColor;
        border: 1px solid $transparent;
    }
}

.reject-btn {
    background: $ottomanRed;
    color: $white;
    padding: 8px 16px;
    border-radius: 100px;
    font-weight: 600;
    border: 1px solid $transparent;

    &:hover {
        background: $americanRed;
        color: $white;
        border: 1px solid $transparent;
    }

    &:active {
        background: $americanRed;
        color: $white;
        border: 1px solid $transparent;
    }
}

.primary-outline-btn {
    background: $white;
    color: $primaryTextColor;
    padding: 8px 16px;
    border-radius: 100px;
    font-weight: 600;
    border: 1px solid $spareWhite;

    &:hover {
        background: $pewterBlue;
        color: $white;
        border: 1px solid $pewterBlue;
    }

    &.active {
        background: $pewterBlue;
        color: $white;
        border: 1px solid $pewterBlue;
    }
}

.white-outline-btn {
    background: $transparent;
    color: $primaryTextColor;
    font-family: 'Segoe UI Regular';
    padding: 8px 16px;
    border: 1px solid $white;
    border-radius: 100px;
    font-weight: 400;
    line-height: 1;

    &.disable {
        color: $silverChalice;
    }

    &:hover {
        background: $white;
        color: $primaryTextColor;
        border: 1px solid $white;
    }

    &:active {
        background: $white;
        color: $primaryTextColor;
        border: 1px solid $white;
    }
}

.dark-tan-btn {
    background: $darkTan;
    color: $white;
    font-family: 'Segoe UI Regular';
    padding: 12px 16px;
    border: 1px solid $transparent;
    border-radius: 100px;
    font-weight: 700;
    line-height: 1;

    &.disable {
        color: $silverChalice;
    }

    &:hover {
        background: $Mud;
        color: $white;
        border: 1px solid $Mud;
    }

    &:active {
        background: $Mud;
        color: $white;
        border: 1px solid $Mud;
    }
}