.form-control {
  border: 1px solid #D1D1D1;
  border-bottom-color: #616161;
  border-radius: 4px;

  &:focus {
      box-shadow: none;
  }



  &:focus {
      border-bottom: 2px solid #0F6CBD;

      &.is_invalid {
          border-bottom: 2px solid #EC2323;
      }
  }

  &.is_invalid {
      box-shadow: none;
      border-bottom: 2px solid #EC2323;
  }

}

.error-message {
  color: #EC2323;
  font-size: 0.875em;
  margin-top: 0.25em;
}