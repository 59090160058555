.grid-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    padding: 20px;
}

.grid-item {
    border: 1px solid #ccc;
    /* padding: 10px; */
    border-radius: 5px;
    /* background-color: white; */
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}

.grid-cell {
    margin-bottom: 10px;
}
