/* src/components/Loader.css */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Faded background */
}

.loader {
  position: relative;
  z-index: 10000;
}

.loader-logo {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
}


/* CSS for Custom Spinner */
.lds-ring,
.lds-ring div {
box-sizing: border-box;
}
.lds-ring {
display: inline-block;
position: relative;
width: 50px;
height: 50px;
margin: 8px auto;
}
.lds-ring div {
box-sizing: border-box;
display: block;
position: absolute;
width: 45px;
height: 45px;
margin: 8px;
border: 4px solid #1976d2;
border-radius: 50%;
animation: lds-ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
border-color:#1976d2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
animation-delay: -0.21s;
}
.lds-ring div:nth-child(2) {
animation-delay: 0s;
}
.lds-ring div:nth-child(3) {
animation-delay: -0.10s;
}
@keyframes lds-ring {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}

