[class^='spectrum-Slider_'] {
    width: 100% !important;
  }
  
  [class^='spectrum-Slider-labelContainer'] {
    margin-bottom: 1rem;
  }
  
  [class^='spectrum-Slider-value'] {
    width: auto !important;
    min-width: 0 !important;
  }
  
  [class^='spectrum-Slider-track'] {
    background-color: #dfe2ee;
    height: 3px;
    border-radius: 9999px;
  }
  
  [class^='spectrum-Slider-track']:last-of-type {
    right: 0;
  }
  
  [class^='spectrum-Slider-track']:nth-of-type(3) {
    background-color: #3a4570;
  }
  
  [class^='spectrum-Slider-handle'] {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 1px;
    background-color: #fff;
    border: 1px solid #c4c8d8;
    box-shadow: 0 2px 5px 0 #e3e5ec;
    border-radius: 9999px;
  }
  