* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    font-family: 'Segoe UI Regular'!important;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Segoe UI Regular'!important;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
}

button {
    font-family: 'Segoe UI Regular'!important;
}

a {
    text-decoration: none;
    color: $primaryTextColor;
}

.form-control {
    &:focus {
        border-color: $chineseWhite;
        box-shadow: 0 0 0 0.25rem rgba(180, 180, 180, 0.25);
    }
}