.compare-inventory-page {
    .user-table-container {
        width: 100%;

        .userTable {
            height: unset;
            flex-grow: 1;
            border-radius: 25px 0px 0px 25px;
        }
    }
}