.msgContainerBox {
    background: #97AEB8;
    border-radius: 25px;

    .msgbox-container {
        padding: 15px;

        .title {
            .text {
                display: none;
            }

            .msg-icn {
                cursor: pointer;
            }

            .search-box {
                display: none;
            }
            .buyer-seller-tabbing-wrapper{
                margin-bottom: 10px;
                opacity: 1;
                width: auto; 
    transition: opacity 1000ms ease, width 1000ms ease;
                .tab-btn{
                    padding: 15px 25px;
                    background-color: transparent;
                    border-radius: 5px;
                    border-top-right-radius: 5px;
                    border: 0;
                    &:focus{
                        outline: 0;
                        box-shadow: unset;
                    }
                    .text{
                        font-family: Segoe UI;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 23.94px;
                        color: #FDFCFA;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        .msg-count{
                            font-family: Segoe UI;
font-size: 13px;
font-weight: 600;
line-height: 17.29px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
background-color: #F8DFA1;
padding: 2px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
height: 18px;
width: 18px;
right: -15px;
top: -10px;
color: #333333;
                        }
                    }
                }
            }
        }
        .order-detail-wrapper{
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 5px 10px;
            box-shadow: 0px 4px 4px 0px #0000001A;
            z-index: 1;
            .order-number-wrapper{
                .heading{
                    font-family: Segoe UI;
font-size: 12px;
font-weight: 400;
line-height: 19px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
margin-bottom: 5px;
                }
                .number{
                    font-family: Segoe UI;
font-size: 15px;
font-weight: 700;
line-height: 19px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
margin: 0;
                }
            }
        }
        .message-box-header{
            display: none;
        }

        .card {
            display: none;
        }

        &.show {
            display: flex;
            flex-direction: column;
            min-width: 375px;
            height: 100%;

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;

                .text {
                    display: block;
                    color: $white;
                    font-size: 18px;
                    font-weight: 700;
                }

                .search-box {
                    display: block;
                    .searchbar__input-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 15px;
                        position: relative;
                    
                        .searchbar__input--icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            right: 0px;
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            outline: none;
                            border-style: none;
                            border-radius: 50%;
                            pointer-events: painted;
                            background-color: transparent;
                            transition: unset;
                    
                            &:focus {
                                ~.searchbar__input {
                                    box-shadow: none;
                                    width: 180px;
                                    border-radius: 100px;
                                    background-color: $white;
                                    border: 1px solid $transparent;
                                    transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
                                    margin-left: 5px;
                                }
                    
                            }
                    
                        }
                    
                        .searchbar__input {
                            border-style: none;
                            height: 40px;
                            width: 40px;
                            padding: 10px;
                            outline: none;
                            border-radius: 50%;
                            transition: unset;
                            background-color: $white;
                            box-shadow: 0px 0px 3px $white;
                            padding-right: 30px;
                            color: $primaryTextColor;
                            text-indent: 6px;
                            margin-left: 10px;
                    
                            &::-webkit-search-cancel-button {
                                -webkit-appearance: none;
                            }
                    
                            &::placeholder {
                                color: $secondaryTextColor;
                            }
                    
                            &:focus {
                                box-shadow: none;
                                width: 180px;
                                border-radius: 100px;
                                background-color: $white;
                                border: 1px solid $transparent;
                                transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
                            }
                        }
                    }
                }
                &.search-focused{
                    gap: 5px;
                    .buyer-seller-tabbing-wrapper {
                        opacity: 0;
                        width: 0;
            transition: opacity 10ms ease, width 10ms ease;
            transition-delay: 10ms; /* Add a delay to match the requirement */
                    }
                }
            }
            
            .order-detail-wrapper{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                padding: 5px 10px;
                box-shadow: 0px 4px 4px 0px #0000001A;
                z-index: 1;
                .order-number-wrapper{
                    .heading{
                        font-family: Segoe UI;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #333333;
    margin-bottom: 5px;
                    }
                    .number{
                        font-family: Segoe UI;
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #333333;
    margin: 0;
                    }
                }
            }
            .message-box-header{
                display: none;
            }

            .card {
                display: flex;
                flex-grow: 1;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;

                .card-body {
                    padding-right: 0px;
                    .card-title{
                        font-family: Segoe UI;
font-size: 20px;
font-weight: 700;
line-height: 26.6px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $primaryTextColor;

                    }
                    .list-group {
                        height: 573px;;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 3px;
                        }
                        /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 3px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 3px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

                        .list-group-item {
                            border-width: 0;

                            .sender {
                                &__msg {
                                    
                                    background: #8F9195;
                                    padding: 12px;
                                    color: $white;
                                    width: 196px;
                                    height: auto;
                                    border-radius: 10px 10px 0px 10px;
                                    pre{
                                        white-space: break-spaces;
                                        overflow-x: hidden;
                                }
                                }

                                &__info {
                                    font-size: 13px;

                                    &--name {
                                        color: $graniteGray;
                                        font-weight: 700;
                                    }

                                    &--time {
                                        color: #737376;
                                    }

                                    &--status {
                                        color: #95CF48;
                                    }
                                }
                            }

                            .receiver {
                                &__msg {
                                    pre{
                                        white-space: wrap;
                                    background: $antiFlashWhite;
                                    padding: 12px;
                                    color: $black;
                                    width: 196px;
                                    height: auto;
                                    border-radius: 0px 10px 10px 10px;
                                }
                                }

                                &__info {
                                    font-size: 13px;

                                    &--name {
                                        color: $graniteGray;
                                        font-weight: 700;
                                    }

                                    &--time {
                                        color: #737376;
                                    }

                                    &--status {
                                        color: #95CF48;
                                    }
                                }
                            }

                            .avatar {
                                display: none;
                            }
                        }
                    }

                }

                .send-msg {
                    margin-bottom: 12px;

                    .form-control {
                        border: 1px solid #686764;
                        border-radius: 100px;
                        padding: 1.25rem;
                    }
                }
            }
        }
//         &.user-msgbox-container{
//             &.show {
//               .card {
//                 .card-body {
//                     .list-group {
//                          height: 435px;
//             }
//         }
//     }
// }
//         }
    }

    .form-control {
        border: 1px solid #D1D1D1;
        border-bottom-color: #616161;
        border-radius: 4px;

        &:focus {
            box-shadow: none;
        }

        &:focus {
            border-bottom: 2px solid #0F6CBD;

            &.is_invalid {
                border-bottom: 2px solid #EC2323;
            }
        }

        &.is_invalid {
            box-shadow: none;
            border-bottom: 2px solid #EC2323;
        }

    }
}

.today-line-wrapper {
    position: relative;
    text-align: center;
    margin-bottom: 12px;

    .today-text {
        position: relative;
        background-color: #F0F0F0;
        font-size: 13px;
        padding: 6px 28px;
        border-radius: 25px;
        z-index: 1;
    }

    .hz-line {
        height: 1px;
        background-color: #D9D9D9;
        position: absolute;
        top: 60%;
        left: 0;
        right: 0;
        z-index: 0;
    }
}

@media screen and (max-width:475px) {
    .msgContainerBox .msgbox-container.show {
        min-width: 275px;
        max-width: 325px;
    }
}