.usersList {
    background-color: #EAECEE;
    padding: 20px;

    &__user {
        border-radius: 0px;

        .user-table-container {
            border-radius: 25px;
            background-color: #FFFFFF;

            .form__inner {
                padding: 20px 20px 0px 20px;
                max-width: 225px;
            }

            .userTable {
                border-radius: 0;
            }
        }
    }

    .user-table-container {
        .userTable {
            height: calc(100vh - 212px);
            background-color: $white;
            border-radius: 25px;

            .data-grid {
                &.MuiDataGrid-root {
                    border: unset;
                    padding: 20px;

                    .MuiDataGrid-main {
                        background-color: $white;
                        font-family: 'Segoe UI Regular';
                        border-radius: 4px;
                        padding: 0px;

                        .MuiDataGrid-virtualScroller {
                            height: auto !important;
                            .MuiDataGrid-topContainer {
                                border-radius: 8px;

                                &::after {
                                    content: none;
                                }

                                .MuiDataGrid-columnHeaders {
                                    border-radius: 8px;

                                    [role=row] {
                                        background: $antiFlashWhite;
                                        border-bottom: unset;

                                        .MuiDataGrid-columnHeader {
                                            .MuiDataGrid-columnHeaderDraggableContainer {
                                                .MuiDataGrid-columnHeaderTitleContainer {
                                                    .MuiDataGrid-columnHeaderTitleContainerContent {
                                                        .MuiDataGrid-columnHeaderTitle {
                                                            color: #6F6F6F;
                                                            font-size: 14px;
                                                            line-height: 18px;
                                                        }
                                                    }
                                                }
                                            }

                                            &:focus {
                                                outline: unset;
                                            }

                                            &:focus-within {
                                                outline: unset;
                                            }

                                            .MuiCheckbox-indeterminate {
                                                color: $secondaryTextColor;
                                            }

                                            .Mui-checked {
                                                color: $secondaryTextColor;
                                            }
                                        }
                                        .MuiDataGrid-columnHeader {
                                            .MuiDataGrid-columnHeaderDraggableContainer {
                                                .MuiDataGrid-columnHeaderTitleContainer {
                                                    .MuiDataGrid-columnHeaderTitleContainerContent {
                                                        .form-control{
                                                            font-family: Segoe UI;
                                                            font-size: 13px;
                                                            font-weight: 400;
                                                            line-height: 17.29px;
                                                            text-align: left;
                                                            text-underline-position: from-font;
                                                            text-decoration-skip-ink: none;
                                                            box-shadow: none;
                                                            color: #616161;
                                                            ::placeholder{
                                                                font-size: 13px;
                                                            font-weight: 400;
                                                            line-height: 17.29px;
                                                            text-align: left;
                                                            text-underline-position: from-font;
                                                            text-decoration-skip-ink: none;
                                                            box-shadow: none;
                                                            color: #616161;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .MuiDataGrid-virtualScrollerContent {
                                .MuiDataGrid-virtualScrollerRenderZone {
                                    .MuiDataGrid-row {
                                        .MuiDataGrid-cell {
                                            &.status-badge {
                                                overflow: visible;
                                            }

                                            &:focus {
                                                outline: unset;
                                            }

                                            &:focus-within {
                                                outline: unset;
                                            }

                                            .MuiIconButton-root {
                                                padding: 4px;

                                                svg {
                                                    fill: #1C1B1F;
                                                    width: 20px;
                                                    height: auto;

                                                    path {
                                                        fill: #1C1B1F;
                                                    }
                                                }
                                            }
                                            .dropdown{
                                                &.show{
                                                    .scrollable-menu {
                                                        &.dropdown-menu{
                                                            &.show{
                                                                transform: translate3d(0px, 38px, 0px) !important;
                                                            }
                                                            }
                                                        } 
                                                }
                                            }
                                        }

                                        &.Mui-selected {
                                            background-color: #97aeb812;

                                            .MuiDataGrid-cellCheckbox {
                                                color: unset;

                                                .Mui-checked {
                                                    color: $secondaryTextColor;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .MuiDataGrid-scrollbar {

                            /* width */
                            &::-webkit-scrollbar {
                                width: 8px;
                                height: 8px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 5px grey;
                                border-radius: 10px;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: $pewterBlue;
                                border-radius: 10px;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: $graniteGray;
                            }
                        }
                    }

                    .MuiDataGrid-footerContainer {
                        border-top: unset;
                        justify-content: flex-start;
                        width: fit-content;

                        .MuiTablePagination-root {
                            .MuiToolbar-root {
                                .MuiTablePagination-selectLabel {
                                    margin-bottom: 0;
                                }

                                .MuiTablePagination-displayedRows {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                &__myinventories {
                    .info-dropdown {
                        overflow: visible;

                        .dropdown-toggle {
                            border: none;
                            margin-left: 4px;

                            &::after {
                                content: none;
                            }
                        }

                        .dropdown-menu {
                            border-left: 11px solid #3A809E;

                            .dropdown-item {
                                font-weight: 400;
                                font-size: 13px;
                                color: #333333;
                            }
                        }
                    }
                }
            }

            &.inventoryFilesTable {
                height: unset;

                .title-bar {
                    padding-inline: 20px;
                    padding-top: 20px;

                    h3 {
                        color: $primaryTextColor;
                        font-weight: 700;
                        font-size: 20px;
                    }
                }

                .data-grid {
                    &.MuiDataGrid-root {
                        padding: 15px;

                        .MuiDataGrid-main {
                            .MuiDataGrid-virtualScroller {
                                border: 1px solid #C8CAC4;
                                border-radius: 10px;

                                .MuiDataGrid-topContainer {
                                    border-radius: 0px;

                                    .MuiDataGrid-columnHeaders {
                                        border-bottom: 1px solid #C8CAC4;
                                        border-radius: 0px;
                                    }
                                }
                            }
                        }

                        .MuiDataGrid-footerContainer {
                            display: none;
                        }
                    }
                }
            }

            .dropdown-nospacing {
                .dropdown-status {
                    padding-inline: 0;
                }
            }
        }

        &.user-table-container-inventoryFiles {
            background: $white;
            border-radius: 25px;
        }
    }
}


.table-responsive-fixed-header {
    position: relative;
    height: 371px;
    /* Adjust as necessary */
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 25px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 25px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .orderRequest-inv-table {
        &.table{
            margin-bottom: 0px;
        }
    }
}
.view_request--wrapper{
    .table-responsive-fixed-header {
        position: relative;
        height: 370px;
        /* Adjust as necessary */
        overflow-y: auto;
    
        /* width */
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            border-radius: 25px;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #D9D9D9;
            border-radius: 25px;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
        .orderRequest-inv-table {
            &.table{
                margin-bottom: 0px;
            }
        }
    }
}

.orderRequest-inv-table {

    thead {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #F0F0F0;
        border-radius: 8px 8px 0 0;

        tr {
            &:first-child {
                th {
                    background-color: #F0F0F0;
                    color: #6F6F6F;
                    font-size: 14px;
                    border: none;
                    
                    &:first-child {
                        border-top-left-radius: 8px;
                        // border-bottom-left-radius: 8px;
                    }
    
                    &:last-child {
                        border-top-right-radius: 8px;
                        // border-bottom-right-radius: 8px;
                    }
                }
            }
            &:nth-child(2) {
            th {
                background-color: #F0F0F0;
                color: #6F6F6F;
                font-size: 14px;
                border: none;
                

                &:first-child {
                    // border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:last-child {
                    // border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
        th{
            padding:6.5px 8px ;
            &:first-child{
                padding-left: 17px;
            }
        }
        }
    }

    tbody {
        tr {
            &.deleted-item-row {
                    background-color: #FFF1F2;
            
                    td {
                        background-color: transparent;
                        &.price-text{
                            color: #3333334D;
                            .per-barrel-text {
                                color: #3333334D;
                            }
                        }
                        &:first-child {
                            padding: 12.5px 0 12.5px 23px;
                            .header{
                            position: relative;

            
                            .removed-text {
                                display:block;
                                position: absolute;
                                top:15px;
                                left: -37px;
                                transform: rotate(-90deg);
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 15.96px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #EC4949;
                            }
                        }
                    }
                    }
                }
                &.rejected-item-row{
                    background-color: #FFFBF1;
            
                    td {
                        background-color: transparent;
                        &.price-text{
                            color: #3333334D;
                            .per-barrel-text {
                                color: #3333334D;
                            }
                        }
                    }
                }
            &:nth-child(2n+1) {
                td {
                    // border-bottom: 0px;

                    &:nth-last-child(-n+3) {
                        border-bottom: 1px solid #dee2e6;
                    }

                    // &:nth-child(n),
                    // &:nth-child(n-1),
                    // &:nth-child(n-2) {
                    // }
                }
            }
            &:last-child{
                td{
                border-bottom: unset;
                &:nth-last-child(-n+3) {
                    border-bottom: unset;
                }
            }
            }

            td {
                &:first-child{
                    padding-left: 23px;
                    .header{
                        .removed-text{
                            display: none;
                        }
                    }
                }
                font-size: 14px;
                // vertical-align: middle;
                padding: 9.62px 8px;
                .inventory-name{
                    font-family: Segoe UI;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.62px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #3A809E;
                    cursor: pointer;
                }
                .company-name{
                    font-family: Segoe UI;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 17.29px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #737376;
                }
                .in-stock-btn {
                        font-family: Segoe UI;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15.96px;
                        padding: 5px 10px;
                        border-radius: 50px;
                        border: 1px solid;
                        background: transparent;
                        margin-top: 5px;
                            &.green-btn{
                                color: #469473;
                                border-color: #469473;
                            }
                            &.red-btn{
                                color: #B10E1C;
                                border-color: #B10E1C;
                            }
                    }  
                    
                    .available-barrels-count{
                        background-color: #75BC84;
                        // height: 20px;
                        // width: 20px;
                        // min-height: 20px;
                        min-width: 20px;
                        // max-height: 20px;
                        // max-width: 20px;
                        border-radius: 5px;
                        padding: 0px 5px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-family: Segoe UI;
font-size: 11px;
font-weight: 600;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
                    }
                    .reserve-barrel-btn{
                        font-family: Segoe UI;
font-size: 13px;
font-weight: 400;
line-height: 17.29px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #3A809E;
border: unset;
padding: 5px;
outline: unset;
box-shadow: unset;
background-color: unset;
display: flex;
align-items: center;
                    }        
                    .barrel-error{
                        position: absolute;
                        bottom: -20px;
                        left: 0;
                        font-family: Segoe UI;
font-size: 13px;
font-weight: 400;
line-height: 17.29px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #B10E1C;
display: flex;
align-items: center;
white-space: nowrap;
                    }
                    .per-barrel-text{
                        font-family: Segoe UI;
font-size: 13px;
font-weight: 400;
line-height: 17.29px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #737376;
                    }
            }

            // &:nth-last-child(2) {
            //     td {
            //         border: none;

            //         &:nth-child(n),
            //         &:nth-child(n-1),
            //         &:nth-child(n-2) {
            //             border: none;
            //         }
            //     }
            // }

            // &:last-child {
            //     td {
            //         border: none;
            //     }
            // }

            &.product-info {
                .details {
                    .title {
                        color: #6F6F6F;
                        font-weight: 700;
                        font-size: 14px;
                    }

                    

                    .text {
                        font-size: 14px;
                        font-weight: 400;

                        &::after {
                            content: "|";
                            color: #D9D9D9;
                            padding-inline: 8px;
                        }

                        &:last-child {
                            &::after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.inventory-doc-table {
    height: 274px;
    width: 100%;
    .upload-doc-datagrid-table {
        border: 1px solid #C8CAC4;
        border-radius: 10px;
        border-collapse: collapse;
        .MuiDataGrid-main {
            .MuiDataGrid-virtualScroller{
                .MuiDataGrid-virtualScrollerContent{
                    height: auto !important;
                .MuiDataGrid-topContainer{
                    .MuiDataGrid-columnHeaders{
                        .MuiDataGrid-row--borderBottom{
                            height: 40px;
                            border-radius: 9px 9px 0 0;
                            border-bottom: 1px solid #C8CAC4;
                            .MuiDataGrid-columnHeader{
                                height: 40px !important;
                                font-family: Segoe UI;
font-size: 14px;
font-weight: 400;
line-height: 18.62px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #6F6F6F;
                            }
                        }
                    }
                }
                .MuiDataGrid-row{
                    max-height: 40px !important;
    min-height: 40px !important;
    --height: 40px !important;
    .MuiDataGrid-cell{
        // height: 40px;
        font-family: Segoe UI;
font-size: 14px;
font-weight: 400;
// line-height: 18.62px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
    }
                }
            }
            .MuiDataGrid-scrollbar{
                top: 40px;
            }
        }
        }
    }
}

.user-table-container {
    .userTable {
        &.inventory-listing-table-wrapper{
        .data-grid {
.MuiDataGrid-topContainer {
    border-radius: unset;

    .MuiDataGrid-row--borderBottom {
        border-radius: 8px;

        .MuiDataGrid-columnHeader {
            .MuiDataGrid-columnHeaderDraggableContainer {
                .MuiDataGrid-columnHeaderTitleContainer {
                    .MuiDataGrid-columnHeaderTitleContainerContent {
                        .form-control {
                            font-family: Segoe UI;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 17.29px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            box-shadow: none;
                            color: #616161;

                            &::placeholder {
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 17.29px;
                                color: #616161;
                            }
                        }
                    }
                }
            }
        }
    }
}

.MuiDataGrid-virtualScrollerContent {
    overflow-y: auto;

    .MuiDataGrid-row {
        .MuiDataGrid-cell {
            font-family: Segoe UI;
            font-size: 14px;
            font-weight: 400;
            // line-height: 18.62px;
            color: #333333;
            .dropdown-nospacing{
                .btn-group{
                    .dropdown-status{
                        font-weight: 400;
                    }
                }
            }
            .lot-id-list-wrapper{
                overflow-x: auto;
                padding-bottom: 5px;
                &::-webkit-scrollbar {
                    height:3px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background:#D9D9D9;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .lot-id-item{
                    font-family: Segoe UI;
font-weight: 400;
font-size: 14px;
line-height: 18.62px;
letter-spacing: 0px;
color: #333333;
background-color: #F7F7F7;
border: 1px solid #E4E4DD;
padding: 3px;
                }
            }

        }
    }
}
.MuiDataGrid-footerContainer{
    justify-content: unset;
    width: fit-content;
    .MuiTablePagination-root{
        overflow: hidden;
        .MuiToolbar-root{
            padding-left: unset;  
            p{
              margin-bottom: 0px;
              font-family: Segoe UI;
              font-size: 14px;
              font-weight: 400;
              line-height: 18.62px;
              text-align: left;
              color: #333333;
            }
          }
      
    }
    .MuiTablePagination-actions{
        .MuiButtonBase-root{
            .MuiSvgIcon-root{
                // &svg{
                    // font-size: 12px;
                    fill: #1C1B1F;
                // }
            }
        }
    }
}
        }
    }
}
}
.same-order-table-wrapper {
    box-shadow: 0px 0px 35px 0px #0000001A;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    max-height: 280px;
    overflow-y: auto;
    z-index: 20;

    table {
        thead {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: #F0F0F0;
            border-radius: 8px 8px 0 0;

            tr {
                th {
                    background-color: #F0F0F0;
                    font-family: Segoe UI;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18.62px;
                    letter-spacing: 0px;
                    color: #6F6F6F;
                    border: none;
                    border-bottom: 1px solid #C8CAC4 !important;
                    padding: 10px 20px;
                    white-space: nowrap;

                    &:first-child {
                        border-top-left-radius: 9px;
                        // border-bottom-left-radius: 8px;
                    }

                    &:last-child {
                        border-top-right-radius: 8px;
                        // border-bottom-right-radius: 8px;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    font-family: Segoe UI;
font-weight: 400;
font-size: 14px;
line-height: 18.62px;
letter-spacing: 0px;
color: #333333;
                    border: none;
                    border-bottom: 1px solid #C8CAC4 !important;
                    padding: 10px 20px;
                    white-space: nowrap;

 &:first-child {
                       color: #3A809E;
                    }
                    
                }
                &:last-child{
                    td{
                    border-bottom: unset !important;
                }
                }
                
            }
        }
    }
}
.price-text {
    color: #3333334D;

    .per-barrel-text {
        color: #3333334D;
    }
}