.wd__header {
    &.wd__header--user {
        .navbar {
            .navbar-collapse {
                .navbar-nav {
                    .nav-item {
                        .nav-link {
                            color: $darkTan;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 22px;

                            &:hover {
                                color: #7d6b3b;
                            }

                            &.dropdown-toggle {
                                &::after {
                                    content: none;
                                }
                                &.show {
                                    svg {
                                        rotate: 180deg;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .header__banner {
            // min-height: 275px;
            padding: 50px;
            border-radius: 35px 35px 0px 0px;
            background-image: url('../../assets/images/header/header_bg.png');
            background-size: cover;
            background-repeat: no-repeat;

            @include media-max(xxl) {
                padding: 25px;
            }

            .banner--title {
                font-size: 48px;
                font-weight: 700;
                line-height: 52px;
                color: $white;
            }
            &.header__for-user{
                padding: 22px 50px;
                @include media-max(xxl) {
                    padding: 0px 25px;
                }
            }
        }
    }
}

// @media screen and (max-width:1400px) {
//     .wd__header.wd__header--user .header__banner {
//         min-height: 225px;
//         padding: 30px;
//     }

//     .wd__header.wd__header--user .header__banner .banner--title {
//         font-size: 36px;
//         line-height: 42px;
//     }
// }