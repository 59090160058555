.add-company-user-wrapper {
    background-color: #EAECEE;
    border-radius: 50px;
    padding: 20px;

    .add-company-user-form-wrapper {
        background-color: #FFF;
        border-radius: 25px;

        .form__inner {
            max-width: 1230px;
            padding: 25px;

            .form-label {
                color: #242424;
                font-size: 16px;
                font-weight: 600;
            }

            .form-control {
                border: 1px solid #D1D1D1;
                border-bottom-color: #616161;
                border-radius: 4px;

                &:focus {
                    box-shadow: none;
                }
                
                &:focus {
                    border-bottom: 2px solid #0F6CBD;

                    &.is_invalid {
                        border-bottom: 2px solid #EC2323;
                    }
                }

                &.is_invalid {
                    box-shadow: none;
                    border-bottom: 2px solid #EC2323;
                }

            }

            .error-message {
                color: #EC2323;
                font-size: 0.875em;
                margin-top: 0.25em;
            }

            .form-check {
                .form-check-input {
                    width: 18px;
                    height: 18px;
                    border-radius: 2px;
                    margin-right: 12px;

                    &:checked {
                        background-color: #496673;
                        border-color: #496673;

                    }

                    &:focus {
                        box-shadow: none;
                        border-color: #dee2e6;
                    }

                }

                .form-check-label {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 400;

                    span {
                        font-size: 20px;
                    }
                }
            }

            .select-company-dropdown {
                border: 1px solid #D1D1D1;
                border-bottom-color: #616161;
                border-radius: 4px;


                &:focus {
                    border-bottom: 2px solid #0F6CBD;

                    &.is_invalid {
                        border-bottom: 2px solid #EC2323;
                    }
                }

                &.is_invalid {
                    box-shadow: none;
                    border-bottom: 2px solid #EC2323;
                }

                .dropdown-toggle {
                    &::after {
                        content: none;
                    }
                }

                .dropdown-menu {
                    .dropdown-item {
                        &:active {
                            color: #212529;
                        }

                        &:hover {
                            color: #212529;
                        }
                    }

                    &.show {
                        width: 100%;
                        max-height: 190px;
                        overflow-x: auto;
                        &::-webkit-scrollbar {
                            width: 3px;
                        }
                    
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: transparent;
                            border-radius: 5px;
                        }
                    
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #D9D9D9;
                            border-radius: 5px;
                        }
                    
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #D9D9D9;
                        }
                        .dropdown-item {
                            &:active {
                                color: #212529;
                            }

                            &:hover {
                                color: #212529;
                            }
                        }
                    }
                }
            }

            [type=radio] {
                &:checked+label {
                    &:before {
                        border: 2px solid #496673;
                    }

                    &:after {
                        background: #496673;
                    }
                }
            }

            .custom-radio-label {
                font-size: 14px;
                font-weight: 600;
                color: #333333;
            }

            .form__btn--group {
                .secondary-btn {
                    padding: 12px 24px;
                    min-width: 125px;
                }

                .primary-btn {
                    padding: 12px 24px;
                    min-width: 125px;
                }
            }
        }
    }

    .edit-inventory-form {
        &.form__inner {
            max-width: 100%;
        }
    }
}



// _datepicker.scss

.input-group-text {
    background-color: transparent;
    border: none;
    padding-left: 0;
    cursor: pointer;

    // svg {
    //   color: $primary-color; // Customize this color as needed
    // }
}

.password-strength-box {
    height: 4px;
    border-radius: 2px;
}