.duplicate-inv-table-wrapper {
    background-color: #FFF;
    border-radius: 25px;
    padding: 25px;

    .data-grid-striped {
        border: 0px;

        .MuiDataGrid-row--borderBottom {
            .MuiDataGrid-columnHeader {
                background-color: #F0F0F0;
                color: #6F6F6F;
                border-bottom: 0px;

                &:focus-within {
                    outline: unset;
                    outline-width: unset;
                    outline-offset: unset;
                }
            }
        }

        .MuiDataGrid-cell {
            &:focus-within {
                outline: unset;
                outline-width: unset;
                outline-offset: unset;
            }
            &:last-child(){
                --width: 200px;
                min-width: var(--width);
                max-width: unset;
            }
        }

        .action-btn {
            color: #9747FF;
        }
    }


    .data-grid-striped .MuiDataGrid-row:nth-child(odd) {
        color: #333;
    }

    .data-grid-striped .MuiDataGrid-row:nth-child(even) {
        color: #9E874A;
    }

    .data-grid-striped .MuiDataGrid-row:nth-child(4n-3),
    .data-grid-striped .MuiDataGrid-row:nth-child(4n-2) {
        background-color: #FFF; // Light gray for every two rows
    }

    .data-grid-striped .MuiDataGrid-row:nth-child(4n-1),
    .data-grid-striped .MuiDataGrid-row:nth-child(4n) {
        background-color: #F0F0F0; // White for the next two rows
    }

}