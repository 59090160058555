.recentActivity {
    .MuiStepper-root {
        .MuiStep-root {
            display: flex;
            align-items: flex-start;

            .MuiStepLabel-root {
                padding: 2px 0 0;
                height: 100%;
                display: flex;
                flex-direction: column;

                .MuiStepLabel-iconContainer {
                    padding: 2px;
                    border: 1px solid #E4E4DD;
                    border-radius: 50%;

                    .MuiSvgIcon-root {
                        &.Mui-active {
                            color: #E4E4DD;
                        }

                        .MuiStepIcon-text {
                            display: none;
                        }
                    }
                }

                .MuiStepLabel-labelContainer {
                    height: 100%;
                    padding-top: 2px;
                    background: #E4E4DD;
                    width: 1px;
                }
            }

            .MuiStepContent-root {
                padding-bottom: 10px;
            }

            // .MuiStepContent-root{
            //     border-left: 1px solid red;
            // }
        }
    }

    .last-child {
        .MuiStepper-root {
            .MuiStep-root {
                display: flex;
                align-items: flex-start;
    
                .MuiStepLabel-root {
                    padding: 2px 0 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
    
                    .MuiStepLabel-iconContainer {
                        padding: 2px;
                        border: 1px solid #E4E4DD;
                        border-radius: 50%;
    
                        .MuiSvgIcon-root {
                            &.Mui-active {
                                color: #E4E4DD;
                            }
    
                            .MuiStepIcon-text {
                                display: none;
                            }
                        }
                    }
    
                    .MuiStepLabel-labelContainer {
                        height: 100%;
                    padding-top: 2px;
                    background: #E4E4DD;
                    width: 1px;
                    }
                }
    
                .MuiStepContent-root {
                    padding-bottom: unset;
                    margin-top: 4px;
                    .MuiCollapse-wrapperInner{
                        .history-content{
                            margin-bottom: 16px;
                            &:last-child{
                                margin-bottom: unset;
                                h6{
                                    line-height: unset;
                                }
                            }
                        }
                    }
                }
    
                // .MuiStepContent-root{
                //     border-left: 1px solid red;
                // }
            }
        }
    }
}