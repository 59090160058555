@import '../../sass/style.scss';

.group-chat-wrapper {
  background-color: $brightGray;
  border-radius: 35px 35px 0px 0px;
  // height: calc(100vh - 82px);
  padding: 15px;

  .group-chat-container {
    background-color: $white;
    border-radius: 25px;
    padding: 15px;

    .group-chat-nav-wrapper {
      .nav {
        &.nav-tabs {
          .nav-link {
            color: #6F6F6F;
            font-weight: 600;
            border: none;

            &.active {
              background-color: transparent;
              color: #333333;
              font-weight: 700;
              border-bottom: 2px solid #333333;
            }
          }
        }
      }
    }

    .group-list-wrapper {
      background-color: $antiFlashWhite;
      border-radius: 20px;
      height: calc(100vh - 191px);
      overflow-y: hidden;

      .chat-search {
        border: none;
        border-radius: 100px;
        // padding-left: 2.75rem;
        padding: 0.5rem 0.75rem 0.5rem 2.75rem;
      }

      .list-group {
        height: calc(100vh - 355px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
          background: #555;
      }
        .list-group-item {
          background-color: $transparent;
          border-width: 0px 0px 2px 0px;
          border-bottom: 2px solid #D9D9D9;

          &.active {
            background-color: $white;
            border-radius: 10px;
          }

          &:last-child {
            border-width: 0px 0px 0px 0px;
          }

          .chat-info-wrapper {
            .avtar {
              background-color: #D9D9D9;
              height: 42px;
              width: 42px;
              color: $primaryTextColor;
              border-radius: 50%;
              font-size: 20px;
              font-weight: 700;

              .active-status {
                position: absolute;
                top: 10px;
                left: 14px;
                height: 15px;
                width: 15px;
                border: 2px solid #F0F0F0;
                border-radius: 50%;

                &.offline {
                  background-color: #8F9195;
                }

                &.online {
                  background-color: #95CF48;
                }
              }
            }

            .chat__info {
              font-weight: 700;
              color: #737376;

              &--name {
                color: $primaryTextColor;
                font-size: 16px;
              }

              &--company {
                color: #737376;
                font-size: 14px;
                max-width: 87px;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &--reqType {
                color: #525E9D;
                font-size: 13px;

                img {
                  margin-right: 4px;
                }
              }
            }
            .chat__info--name {
              color: #333333;
              font-size: 16px;
              font-weight: 700;
          }
            .time__info {
              color: #737376;
              font-weight: 700;
              font-size: 12px;
            }

            .msg-preview {
              color: $primaryTextColor;
              font-weight: 700;
              font-size: 14px;
              padding-left: 55px;
              display: -webkit-box;           /* Enables the flexbox container for truncation */
    -webkit-line-clamp: 2;          /* Limits the text to 2 lines */
    -webkit-box-orient: vertical;   /* Specifies the box orientation as vertical */
    overflow: hidden;               /* Hides the overflowing content */
    text-overflow: ellipsis;        /* Adds ellipsis when the text exceeds */
    max-height: 3em;                /* Restricts the height to show 2 lines */
    line-height: 1.5em;

              @include media-max(sm) {
                padding-left: 0;
              }
            }

            .msg-count {
              background-color: #525E9D;
              width: 24px;
              height: 24px;
              padding: 10px;
              color: $white;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .message-list-wrapper {
      background-color: $white;

      .container {
        display: flex;
        flex-direction: column;
        padding: 0;
      }

      .btn-outline-offer {
        color: #525E9D;
        padding: 4px 10px;
        font-weight: 600;
        font-size: 15px;
        border: 1px solid #525E9D;
        border-radius: 5px;
      }
      .info-icon{
        cursor: pointer;
      }

      .message-list-card {
        .card {
          border: none;
          position: unset;

          .card-body {
            padding: 0;
            .list-group {
              height: calc(100vh - 315px);
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 5px;
            }
      
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 5px;
            }
      
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 5px;
            }
      
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

              .list-group-item {
                padding-inline: 0;
                border: none;

                .sender {
                  &__msg {
                    background: $antiFlashWhite;
                    padding: 12px;
                    color: $black;
                    max-width: 300px;
                    height: auto;
                    border-radius: 10px 0px 10px 10px;
                    margin-top: 12px;
                    pre{
                      overflow-x: hidden;
                      white-space: break-spaces;
                    }
                  }

                  &__info {
                    font-size: 13px;

                    &--name {
                      color: $graniteGray;
                      font-weight: 700;
                    }

                    &--time {
                      color: #737376;
                    }

                    &--status {
                      color: #95CF48;
                    }
                  }
                }

                .receiver {
                  &__msg {
                    background: $antiFlashWhite;
                    padding: 12px;
                    color: $black;
                    max-width: 300px;
                    height: auto;
                    border-radius: 0px 10px 10px 10px;
                    pre{
                      overflow-x: hidden;
                      white-space: break-spaces;
                    }
                  }

                  &__info {
                    font-size: 13px;

                    &--name {
                      color: $graniteGray;
                      font-weight: 700;
                    }

                    &--time {
                      color: #737376;
                    }

                    &--status {
                      color: #95CF48;
                    }
                  }
                }

                .avatar {
                  background-color: #D9D9D9;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  height: 42px;
                  width: 42px;
                  min-width: 42px;
                  font-weight: 700;
                  margin-inline: 6px;
                  border-radius: 50%;
                }
              }
            }
          }

          .card-footer {
            // padding-inline: 0;

            .send-msg {
              .form-control {
                padding: 1.375rem .75rem;
                border-radius: 10px;
                &::-webkit-scrollbar{
                  width: 0px;
                }
              }
            }
          }
        }
      }
      // .admin-message-box {

      //   .admin-message-box-inner {


            // Sidebar Styling
            .sidebar {

                .buyer-info-wrapper {
                    border-radius: 20px;
                    background-color: #E3EBEC;
                    padding: 10px 15px;
                    position: relative;
                    cursor: pointer;

                    .buyer-text {
                        font-family: Segoe UI;
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 19.95px;
                        text-align: left;
                        color: #636363;
                        margin-bottom: 5px;
                    }

                    .buyer-name {
                        font-family: Segoe UI;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 23.94px;
                        color: #636363;
                        margin: 0;

                    }

                    .msg-count-wrapper {
                        position: absolute;
                        top: 10px;
                        right: 15px;
                        padding: 5px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 25px;
                        width: 25px;
                        background-color: #525E9D;
                        font-family: Segoe UI;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 15.96px;
                        color: #FFFFFF;
                    }
                }

                .seller-list {
                    background-color: #F0F0F0;
                    border-radius: 20px;
                    margin-top: 15px;
                    height: calc(100vh - 275px);
                    overflow-y: hidden;

                    .seller-list-header {
                        padding: 15px 10px;
                        border-bottom: 1px solid #D9D9D9;

                        .heading {
                            font-family: Segoe UI;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 21.28px;
                            text-align: left;
                            color: #333333;
                            margin: 0;
                            padding-inline: 5px;
                        }

                        .chat-search {
                            border-radius: 100px;
                            background-color: #FDFCFA;
                            border: unset;

                            &::placeholder {
                                color: #6F6F6F;
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 21.28px;
                            }
                        }
                    }
                    .list-group{
                      height: calc(100vh - 283px);
                      overflow-y: auto;
                        &::-webkit-scrollbar {
                            width: 5px;
                        }
            
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                            border-radius: 5px;
                        }
            
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #888;
                            border-radius: 5px;
                        }
            
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                    .seller {
                        margin: 10px 18px;
                        margin-bottom: 0px;
                        background-color: transparent;
                        padding: 0px;
                        padding-bottom: 10px;

                        .seller-inner {
                            padding:12px 12px;
                            border-radius: 10px;
                            position: relative;
                            cursor: pointer;

                            .seller-name {
                                font-family: Segoe UI;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 23.94px;
                                color: #525E9D;
                                padding-right: 30px;
                            }

                            .seller-item-list {
                                list-style-type: none;
                                padding: 0;
                                margin: 0;
                                background-color: transparent;

                                .seller-list-item {
                                    font-family: Segoe UI;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 18.62px;
                                    color: #333333;
                                    padding-block: 3px;
                                }
                            }

                            .msg-count-wrapper {
                                position: absolute;
                                top: 12px;
                                right: 12px;
                                padding: 5px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 25px;
                                width: 25px;
                                background-color: #525E9D;
                                font-family: Segoe UI;
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 15.96px;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
                }
            }

            // Chat Area Styling
            .chat {
                background-color: #fff;

                .chat-header {
                    padding: 15px 0px;
                    border-bottom: 1px solid #D9D9D9;
                    display: none;

                    .chat-search {
                        background-color: #FDFCFA;
                        border: 1px solid #ABABAB;
                        border-radius: 100px;

                        &::placeholder {
                            font-family: Segoe UI;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 21.28px;
                            text-align: left;
                            color: #6F6F6F;
                        }
                    }
                }
                .message-box-header{
                  display: flex;
              }

                .card {
                    border: unset;
                    position: unset;

                    .card-body {
                        padding-inline: 0px;
                        // border-bottom: 1px solid #D9D9D9 ;
                        height: calc(100vh - 330px);
                        overflow-y: auto;
                        &::-webkit-scrollbar {
                            width: 5px;
                        }
            
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                            border-radius: 5px;
                        }
            
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #888;
                            border-radius: 5px;
                        }
            
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                        .list-group {
                          height: unset;
                          overflow-y: unset;
                            .list-group-item {
                                padding-inline: 0px;
                                border: unset;
                                .sender {
                                    max-width: 280px;
                                    .sender__msg {
                                        background-color: #F0F0F0;
                                        padding: 10px 15px;
                                        border-radius: 10px;
                                        border-top-right-radius: unset;

                                        pre {
                                            font-family: Segoe UI;
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 19.6px;
                                            text-align: left;
                                            color: #000000;
                                        }
                                    }

                                    .sender__info {
                                        .sender__info--name {
                                            font-family: Segoe UI;
                                            font-size: 13px;
                                            font-weight: 700;
                                            line-height: 18.2px;
                                            text-align: left;
                                            color: #616161;
                                            text-transform: capitalize;
                                        }

                                        .sender__info--time {
                                            //styleName: chat/message-stamp;
                                            font-family: Inter;
                                            font-size: 13px;
                                            font-weight: 400;
                                            line-height: 15.6px;
                                            text-align: left;
                                            color: #737376;
                                        }

                                        .sender__info--status {
                                            color: #95CF48;
                                        }
                                    }
                                }
                                .avatar{
                                  background-color: #D9D9D9;
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  height: 42px;
                                  width: 42px;
                                  min-width: 42px;
                                  font-weight: 700;
                                  margin-inline: 6px;
                                  border-radius: 50%;
                                }

                                .receiver {
                                    max-width: 280px;
                                    .receiver__info {
                                        .receiver__info--name {
                                            font-family: Segoe UI;
                                            font-size: 13px;
                                            font-weight: 700;
                                            line-height: 18.2px;
                                            text-align: left;
                                            color: #616161;
                                            text-transform: capitalize;
                                            margin-bottom: 5px;
                                        }

                                        .receiver__info--time {
                                            //styleName: chat/message-stamp;
                                            font-family: Inter;
                                            font-size: 13px;
                                            font-weight: 400;
                                            line-height: 15.6px;
                                            color: #737376;
                                        }
                                    }

                                    .receiver__msg {
                                        background-color: #F0F0F0;
                                        padding: 10px 15px;
                                        border-radius: 10px;
                                        border-top-left-radius: unset;

                                        pre {
                                            font-family: Segoe UI;
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 19.6px;
                                            text-align: left;
                                            color: #000000;
                                            overflow-x: hidden;
                                            white-space: break-spaces;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .card-footer {
                        padding-inline: 0;
            
                        .send-msg {

                          .form-control {
                            padding: 1.375rem .75rem;
                            border-radius: 10px;
                            &::-webkit-scrollbar{
                              width: 0px;
                            }
                          }
                        }
                      }

//                                                                                 .card-footer {
//                                                                                     padding: 1rem 0.75rem;
//                                                                                     border-radius: 10px;
//                                                                                     border: 1px solid #686764;
//                                                                                     margin-top: 10px;

//                                                                                     .send-msg {
//                                                                                         display: flex;
//                                                                                         justify-content: space-between;
//                                                                                         align-items: center;
//                                                                                         gap: 10px;

//                                                                                         div {
//                                                                                             width: calc(100% - 100px);

//                                                                                             // .send-input-inner {
//                                                                                             //     width: 100%;

//                                                                                                 input {
//                                                                                                     border: unset;
//                                                                                                     border-radius: unset;
//                                                                                                     box-shadow: unset;
//                                                                                                     width: 100%;

//                                                                                                     &:focus {
//                                                                                                         box-shadow: unset;
//                                                                                                     }

//                                                                                                     &::placeholder {
//                                                                                                         font-family: Segoe UI;
// font-size: 15px;
// font-weight: 400;
// line-height: 20.25px;
// color: #737376;

//                                                                                                     }
//                                                                                                 }

//                                                                                                 .form-control {
//                                                                                                     padding: unset;
//                                                                                                     border-radius: unset;
//                                                                                                 }
//                                                                                             // }

//                                                                                             .file__upload--info {
//                                                                                                 border: 1px solid #D9D9D9;
//                                                                                                 border-radius: 4px;
//                                                                                                 padding: 0px;
//                                                                                                 height: auto;

//                                                                                                 .file-name {
//                                                                                                     font-family: Segoe UI;
//                                                                                                     font-size: 13px;
//                                                                                                     font-weight: 400;
//                                                                                                     line-height: 17.29px;
//                                                                                                     text-align: left;
//                                                                                                     color: #8F9195;
//                                                                                                     padding: 4px;
//                                                                                                 }

//                                                                                                 .trash-img-wrapper {
//                                                                                                     background-color: #F0F0F0;
//                                                                                                     padding: 4px;
//                                                                                                     // padding-top: 1px;
//                                                                                                     border-top-right-radius: 5px;
//                                                                                                     border-bottom-right-radius: 5px;
//                                                                                                     cursor: pointer;
//                                                                                                 }
//                                                                                             }
//                                                                                         }
//                                                                                     }
//                                                                                 }
                }
            }

        }
    }
    }
//   }
// }

.offer-req-container {
  z-index: 9;
  background-color:rgba(0, 0, 0, 0.3411764706);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: end;
    padding: 0px;
    border-radius:0 25px 25px 0;
    .offer-req-left-inner{
      width: calc(100% - 370px);
    }

    .offer-req-inner{
      width: 370px;
      background-color:#FFFFFF;
      height: 100%;
      // border-radius: 25px;
      padding: 22px;
      padding-right: 10px;
      padding-top: 0px;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      overflow-y: hidden;
      .close-icon{
        cursor: pointer;
      }
    }

  .list-group {
    // border-radius: 25px;
    height: 100%;
    overflow-y: auto;
    --bs-list-group-bg: transparent ;
    &::-webkit-scrollbar {
      width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
      background: #555;
  }

    .list-group-item {
      display: flex;
      flex-direction: column;
      padding: 1rem 0rem;
      border-top-width: 0px;
      border-left-width: 0px;
      border-right-width: 0px;

      &:last-child {
        border-radius: 0px;
      }

      .title {
        color: $darkTan;
        font-size: 24px;
        font-weight: 700;
        word-break: break-word;
      }

      .sub-title {
        color: $DeepSpaceSparkle;
        font-size: 14px;
        font-weight: 700;
      }

      .info {
        color: $primaryHeadingColor;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}