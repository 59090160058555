// @import '../../sass/style.scss';
@import '../../sass/abstracts/variables';

.users {
    background-color: #EAECEE;
    border-radius: 35px 35px 0px 0px;
}

.usersList {
    font-family: 'Segoe UI Regular';
    border-radius: 35px 35px 0px 0px;

    // background-color: red;
    .usersList__header--wrapper {
        padding-inline: 20px;
        .header--title {
            .title {
                font-size: 25px;
                font-weight: 600;
                line-height: 34px;
            }
        }

        .header--menu {
            .header--menu-items {
                .menu-items__search {
                    input[type=search] {
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        border: 0;
                    }
                }

                .menu-items__btnGroup {
                    .white-outline-btn {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 16px;
                        line-height: 1;
                    }
                    button {
                        .review--number {
                            font-weight: 700;
                            height: 28px;
                            width: 28px;
                        }
                    }

                    &.btnGroup-border {
                        border-left: 1px solid $pastelGray;
                        border-right: 1px solid $pastelGray;
                    }

                    .counting {
                        background: $transparent;
                        color: $primaryTextColor;
                        font-family: 'Segoe UI Regular';
                        font-weight: 700;
                        line-height: 1;
                        padding: 8px 16px;
                        border: 1px solid $white;
                        border-radius: 50%;
                        // height: 40px;
                        // width: 40px;
                    }
                }
            }
        }
    }
}

// .input-container {
//     position: relative;
//     display: flex;
//     align-items: center;
//   }
  
//   .input {
//     width: 40px;
//     height: 40px;
//     border-radius: 10px;
//     border: none;
//     outline: none;
//     padding: 18px 16px;
//     background-color: white;
//     cursor: pointer;
//     transition: all .5s ease-in-out;
//   }
  
//   .input::placeholder {
//     color: transparent;
//   }
  
//   .input:focus::placeholder {
//     color: rgb(131, 128, 128);
//   }
  
//   .input:focus,.input:not(:placeholder-shown) {
//     background-color: #fff;
//     border: 1px solid rgb(98, 0, 255);
//     width: 290px;
//     cursor: none;
//     padding: 18px 16px 18px 40px;
//   }
  
//   .icon {
//     position: absolute;
//     left: 0;
//     top: 0;
//     height: 40px;
//     width: 40px;
//     background-color: #fff;
//     border-radius: 10px;
//     z-index: -1;
//     fill: rgb(98, 0, 255);
//     border: 1px solid rgb(98, 0, 255);
//   }
  
//   .input:hover + .icon {
//     transform: rotate(360deg);
//     transition: .2s ease-in-out;
//   }
  
//   .input:focus + .icon,.input:not(:placeholder-shown) + .icon {
//     z-index: 0;
//     background-color: transparent;
//     border: none;
//   }
  
.purple-cell {
    color: #8125DF;
    font-weight: bold; // Set the text color to purple
}
