.rti--container {
    --rti-bg: #FFF;
    --rti-border: var(--bs-border-color);
    --rti-main: $chineseWhite;;
    --rti-radius: 0.375rem;
    --rti-s: 0.5rem;
    --rti-tag: #97AEB8;
    --rti-tag-remove: #E53E3E;

    margin-bottom: 12px;

    // .rti--input {
    //     width: 100%;

    //     &:focus {
    //         border-color: $chineseWhite;
    //         box-shadow: 0 0 0 0.25rem rgba(180, 180, 180, 0.25);
    //     }
    // }

    .rti--tag {
        color: $white;
        background: #97AEB8;

        span {
            line-height: 1.25;
            max-width: 375px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        button {
            color: $white;

        }
    }
}