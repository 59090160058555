.wd__alert {
    &.alert {
        display: flex;
        background-color: $white;
        box-shadow: 0px 4px 15px 0px #0000001A;
        border-radius: 5px;
        border-top-width: 2px;
        border-top-style: solid;
        padding: 12px;
        margin: 12px 0px;

        .alert-heading {
            &.h4 {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 0;
                font-weight: 600;
            }
        }

        &.alert-success {
            color: #25B634;
            border-top-color: #25B634;
        }
    }
}