.profile-wrapper {
    padding-inline: 48px;
    min-height: calc(100vh - 427px);
}

.usersList {
    &.profile-admin {
        min-height: calc(100vh - 80px);
    }
}

.usersList {
    &.profile-user {
        border-radius: 0px;
        background-color: #F8F8F8;

        .usersList__header--wrapper {
            padding-inline: 0px;
            padding-bottom: 12px;
            border-bottom: 1px solid #D9D9D9;
            max-width: 90%;
            margin-inline: auto;
        }

        .profile-wrapper {
            max-width: 90%;
            margin-inline: auto;
        }
    }
}

.profile-details {
    .details-card {
        border-bottom: 1px solid #F0F0F0;

        .title {
            color: #8F9195;
            font-weight: 700;
            font-size: 14px;

            .ul-title {
                background-color: #3A809E;
                height: 2px;
                width: 40px;
            }
        }

        .desc {
            color: #333333;
            font-size: 14px;
        }
    }

    .profile-avatar {
        height: 150px;
        width: 150px;
        border-radius: 50%;
        // background-color: #8F9195;

        .profile-edit {
            cursor: pointer;
            bottom: 30px;
            right: 0px;
        }

        .profile-delete {
            cursor: pointer;
            bottom: 70px;
            right: -10px;
        }
    }

    .profile-menu {
        background-color: #F0F0F0;
    }

    .form__inner {
        .btn {
            min-width: 125px;
        }

        .form-label {
            color: #242424;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.upload-input-label {
    font-size: 14px;
    font-weight: 700;
    color: #737376;
    padding-inline: 12px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal_imageUploader {
    &.modal {
        padding-right: 15px;

        .modal-dialog {
            .modal-content {
                border: 0px;
            }

            .modal-body {
                border-color: transparent;

                .reactEasyCrop_Image {
                    width: 100%;
                    // height: 100%;
                    // object-fit: contain;
                }

                // .reactEasyCrop_CropArea {
                //     &.reactEasyCrop_CropAreaRound {
                //         width: 250px;
                //         height: 250px;
                //     }
                // }
            }
        }
    }
}

.image-uploader-wrapper {
    .profile-avatar {
        .profile-edit {
            cursor: pointer;
            bottom: 30px;
            right: 8px;
        }

        .profile-delete {
            cursor: pointer;
            bottom: 70px;
            right: -10px;
        }
    }
}

.password-list {
    background-color: #F0F0F0;

    .list-group {
        list-style-type: disc;
    }
}

@media screen and (max-width:575px) {
    .profile-wrapper {
        padding-inline: 24px;
    }
}