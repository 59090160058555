@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/breakpoints';

.success-order-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 48px;
    background-color: #F8F8F8;
    min-height: calc(100vh - 316px);

    @include media-max(sm) {
        padding: 60px 15px;
    }

    img {
        max-width: 275px;
        height: auto;
    }

    .order-deatil-card {
        padding: 24px;
        background-color: $white;
        border-radius: 10px;
        max-width: 700px;
    }

    .empty-cart-card {
        padding: 12px;
        border-radius: 10px;
        max-width: 700px;
    }
}
@media (max-width:1399.98px) {
    .customer--view__cart .customer--view-cart-inner {
        min-height: calc(100vh - 362px);
    }
    .success-order-wrapper {
        min-height: calc(100vh - 266px);
    }
}