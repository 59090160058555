.form__inner {

  // .form-select, .form-check-input {
  //   &.is_invalid {
  //     border-bottom-color: #EC2323;
  //   }
  // }
    .form-control {
        border-radius: 4px;
        border-bottom-color: #616161;

    
        &:focus {
          border-bottom: 2px solid #0F6CBD;
    
          &.is_invalid {
            border-bottom: 2px solid #EC2323;
          }
        }

        &.is_invalid {
            box-shadow: none;
            border-bottom: 2px solid #EC2323;   
          }
          
        
      }

      .error-message {
        color: #EC2323;
        font-size: 0.875em;
        margin-top: 0.25em;
      }
}
.btn-skip{
  border: 1px solid #496673;
                                    border-radius: 100px;
                                    padding: 6px 16px;
                                    background-color: transparent;
                                    color: #496673;
                                    font-family: Segoe UI;
                                    span{
                                        font-size: 16px;
                                    font-weight: 600;
                                    line-height: 20px;
                                    }

                                    svg{
                                        margin-left: 5px;
                                        margin-top: 2px;
                                    }
                                    &:hover{
                                        background-color: #496673a8;
                                    color: #fff;
                                    border-color: #fff;
                                    }
}