.modal__wd {
    &.modal {
        .modal-dialog {
            .modal-content {
                background-color: $primaryModalBg;
                border-radius: 25px;

                .modal-header {
                    margin-bottom: 80px;
                    border-bottom: 0px;

                    .modal-title {
                        color: $deepChampagne;
                        font-size: 14px;
                        line-height: 32px;
                    }

                    .modal-text {
                        color: $white;
                        font-size: 25px;
                        line-height: 32px;
                    }
                }



                .modal-body {
                    background-color: $white;
                    margin: 20px 20px 0px 20px;
                    border-radius: 15px 15px 0px 0px;
                    padding: 0;

                    .modal-body__title {
                        color: $primaryHeadingColor;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                    }

                    .input-group {
                        textarea {
                            resize: none;
                            // height: 290px;
                        }
                    }
                }

                .modal-footer {
                    background-color: $white;
                    margin: 0px 20px 20px 20px;
                    border-radius: 0px 0px 15px 15px;
                    border-top: 0px;

                    &.bg-transparent {
                        background-color: $transparent;
                    }
                }
            }
        }

        &.modal__inviteUser {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-body__tags-input {
                            min-height: 225px;
                            border: 1px solid #dee2e6;
                            border-radius: 0.5rem;

                            .rti--container {
                                border: unset;

                                .rti--input {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.user--modal__inviteUser {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-body__tags-input {

                            .rti--container {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal__wd_small {
    &.modal {
        .modal-dialog {
            .modal-content {
                background-color: $primaryModalBg;
                border-radius: 25px;

                .modal-header {
                    margin-bottom: 0px;
                    border-bottom: 0px;

                    .modal-title {
                        color: $deepChampagne;
                        font-size: 14px;
                        line-height: 32px;
                    }

                    .modal-text {
                        color: $white;
                        font-size: 25px;
                        line-height: 32px;
                    }
                }

                .modal-header-small {
                    margin-bottom: 0px;
                    border-bottom: 0px;

                    .modal-title {
                        color: $deepChampagne;
                        font-size: 14px;
                        line-height: 32px;
                    }

                    .modal-text {
                        color: $white;
                        font-size: 25px;
                        line-height: 32px;
                    }
                }

                .modal-body {
                    background-color: $white;
                    margin: 20px 20px 0px 20px;
                    border-radius: 15px 15px 0px 0px;
                    padding: 0;

                    .modal-body__title {
                        color: $primaryHeadingColor;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                    }

                    .input-group {
                        textarea {
                            resize: none;
                            // height: 290px;
                        }
                    }
                }

                .modal-footer {
                    background-color: $white;
                    margin: 0px 20px 20px 20px;
                    border-radius: 0px 0px 15px 15px;
                    border-top: 0px;

                    &.bg-transparent {
                        background-color: $transparent;
                    }
                }
            }
        }

        &.modal__inviteUser {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        padding: 0;
                        .modal-body__tags-input {
                            min-height: 225px;
                            border: 1px solid #dee2e6;
                            border-radius: 0.5rem;

                            .rti--container {
                                border: unset;

                                .rti--input {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal {
    &.modal__wd {
        &--submitSellReq {
            .modal-dialog {
                .modal-content {
                    .modal-header {
                        background-color: $primaryModalBg;
                        margin-bottom: 0px;
                        // padding: 24px;

                        .modal-title {
                            color: #CFC3A5;
                            font-size: 24px;
                            font-weight: 700;
                        }

                        .modal-text {
                            color: $white;
                            font-size: 18px;
                            line-height: 22px;
                            font-weight: 700;
                        }

                        .btn-close {
                            position: absolute;
                            top: 24px;
                            right: 24px;
                            color: $white;
                            background-image: unset;
                        }
                    }

                    .modal-body {
                        margin: 0px;
                        border-radius: 0px;
                        padding: 30px;

                        .modal-body__upload-file {
                            .file__upload--container {
                                .file__upload--dropzone {
                                    background-color: #F7F7F7;
                                    color: #737376;
                                    font-size: 14px;

                                    .fp-label {
                                        padding: 8px 30px;
                                    }
                                }

                                .file__upload--info {
                                    padding: 0px;
                                    border: 1px solid #D9D9D9;
                                    border-radius: 5px;

                                    .file-name {
                                        font-size: 13px;
                                        font-weight: 400;
                                        line-height: 1;
                                    }

                                    .file-delete {
                                        font-size: unset;
                                        font-weight: unset;
                                        cursor: pointer;
                                        background-color: #F0F0F0;
                                        padding: 4px;
                                        border-radius: 0 5px 5px 0;
                                    }
                                }
                            }

                            .basic-faq {
                                background-color: #F0F0F0;
                                border-radius: 5px;
                                padding: 12px;
                            }
                                                        .upload--faq-header {
                                                            font-family: Segoe UI;
                                                            font-size: 24px;
                                                            font-weight: 700;
                                                            line-height: 22px;
                                                            text-align: left;
                                                            text-underline-position: from-font;
                                                            text-decoration-skip-ink: none;
                                                            color: #242424;
                                                            padding-left: 4px;
                                                        }
                            .upload_csv--user__faq{
                                .accordion{
                                    .accordion-item{
                                        background: #F0F0F0;
                                        padding:20px;
                                        border-radius: 10px;
                                        border: 1px solid #F2F2F2;
                                        margin-block: 15px;
                                        .accordion-header{
                                            button{
                                                background-color: transparent;
                                                box-shadow: unset;
                                                font-family: Segoe UI;
                                                font-size: 16px;
                                                font-weight: 600;
                                                line-height: 22px;
                                                color: $primaryHeadingColor;
                                                padding: 0px;

                                                &.collapsed{
                                                &::after{
                                                    background-image: url("../../assets/images/Plus.png");
                                                    transform: unset;
                                                }
                                            }
                                            &::after{
                                                background-image: url("../../assets/images/Minus.png");
                                                transform: unset;
                                            }
                                            }
                                        }
                                        .accordion-collapse{
                                            .accordion-body{
                                                background-color: $white;
                                                font-family: Segoe UI;
                                                font-size: 16px;
                                                font-weight: 400;
                                                line-height: 25px;
                                                color: $primaryHeadingColor;
                                                border-radius: 10px;
                                                margin-top: 10px;
                                            }
                                        }
                                    }
                                }
                            }
                            .instruction-wrapper{
                                .left-part{
                                    border-right: 1px solid #ABABAB;
                                }
                                .next-btn{
                                    border: 1px solid #496673;
                                    border-radius: 100px;
                                    padding: 2px 20px;
                                    background-color: transparent;
                                    color: #496673;
                                    font-family: Segoe UI;
                                    p{
                                        font-size: 18px;
                                    font-weight: 600;
                                    line-height: 22px;
                                    }

                                    svg{
                                        margin-left: 10px;
                                        margin-top: 2px;
                                    }
                                    &:hover{
                                        background-color: #496673;
                                    color: $white;
                                    border-color: $white;
                                    }
                                }
                            }
                        }
                    }

                    .modal-footer {
                        margin: 0;
                        border-top: unset;
                    }
                }
            }
        }
    }
    &.invited-modal{
    .invited-close{
        padding-inline: 40px;
    }
        }
    }
