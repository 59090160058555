/* ClearRefinements */

.ais-ClearRefinements,
.ais-ClearRefinements-button {
  color: rgba(33, 36, 61, 0.7);
}

.ais-ClearRefinements-button--disabled {
  color: rgba(33, 36, 61, 0.5);
}
.clear-filters {
    align-items: center;
    display: flex;
  }