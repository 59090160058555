.support-request-list-wrapper {
    padding-bottom: 70px;
    .user-table-container {
        .userTable {
            .data-grid {
                .MuiDataGrid-main {
                    .MuiDataGrid-virtualScroller {

                        // overflow-y: hidden;
                        /* width */
                        &::-webkit-scrollbar {
                            width: 10px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #888;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }

                        .MuiDataGrid-topContainer {
                            border-radius: unset;

                            .MuiDataGrid-row--borderBottom {
                                border-radius: 8px;

                                .MuiDataGrid-columnHeader {
                                    .MuiDataGrid-columnHeaderDraggableContainer {
                                        .MuiDataGrid-columnHeaderTitleContainer {
                                            .MuiDataGrid-columnHeaderTitleContainerContent {
                                                .form-control {
                                                    font-family: Segoe UI;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    line-height: 17.29px;
                                                    text-align: left;
                                                    text-underline-position: from-font;
                                                    text-decoration-skip-ink: none;
                                                    box-shadow: none;
                                                    color: #616161;

                                                    &::placeholder {
                                                        font-size: 13px;
                                                        font-weight: 400;
                                                        line-height: 17.29px;
                                                        color: #616161;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .MuiDataGrid-virtualScrollerContent {
                            overflow-y: auto;

                            .MuiDataGrid-row {
                                .MuiDataGrid-cell {
                                    font-family: Segoe UI;
                                    font-size: 14px;
                                    font-weight: 400;
                                    // line-height: 18.62px;
                                    color: #333333;
                                    .dropdown-nospacing{
                                        .btn-group{
                                            .dropdown-status{
                                                font-weight: 400;
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}