// sass/abstracts/_variables.scss :

$primaryTextColor: #333333;
$secondaryTextColor: #6F6F6F;
$primaryHeadingColor: #242424;
$secondaryBtnBg: #EDEDED;
$primaryModalBg: #34404A;
$white: #FFFFFF;
$black: #000000;
$transparent: #FFFFFF00;
$danger: #FF0000;
$ottomanRed: #EC2323;
$americanRed: #BB2D3B;
$silverChalice: #ABABAB;
$graniteGray: #616161;
$pewterBlue: #496673;
$americanSilver: #D1D1D1;
$deepChampagne: #F8DFA1;
$chineseWhite: #E1E1E1;
$antiFlashWhite: #F0F0F0;
$pastelGray: #C8CAC4;
$spareWhite: #E4E4DD;
$brightGray: #EAECEE;
$lightSeaGreen: #28C1B8;
$americanGreen: #25B634;
$charcoal: #444444;
$darkTan: #9E874A;
$Mud: #6B5C32;
$LightSilver: #D7D7D7;
$DeepSpaceSparkle: #496673;