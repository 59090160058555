.ck {
    &.ck-rounded-corners {
        border-radius: 4px;
    }

    &.ck.ck-editor__top {
        .ck-sticky-panel {
            .ck-sticky-panel__content {
                border: unset;
                border-top-width: 1px;
                border-right-width: 1px;
                border-bottom-width: 0px;
                border-left-width: 1px;
                border-style: solid;
                border-color: #ccced1;
            }
        }
    }

    // .ck {
    //     &.ck-editor__main {
    //         .ck-editor__editable:not(.ck-focused) {
    //             border-top-color: transparent;
    //         }
    //     }
    // }
}